import React from "react";
import "./product.scss";

const Product = () => {
  const faceCount = 9;

  return (
    <div className="containers">
      <div className="carousel">
        {Array.from({ length: faceCount }).map((_, index) => (
          <div key={index} className="carousel__face">
            <span></span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
