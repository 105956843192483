/* eslint-disable import/no-anonymous-default-export */
import BonchLogo from "./bonch-logo.png";
import CiscoLogo from "./cisco-logo.png";
import EmailLogo from "./email.svg";
import CloudEssentials from "./gcloud-essentials.png";
import GoogleLogo from "./google-logo.png";
import HtbLogo from "./htb-logo.jpg";
import HtbReward from "./htb-reward.png";
import ImageOne from "./image-1.jpg";
import ImageTwo from "./Image-2.jpg";
import ImageThree from "./image-3.jpg";
import ImageFour from "./image-4.jpg";
import ImageFive from "./image-5.jpg";
import ImageSix from "./image-6.png";
import ImageSeven from "./image-7.jpg";
import ImageEight from "./image-8.png";
import ImageNine from "./image-9.png";
import ImageTen from "./image-10.jpg";
import ImageEleven from "./image-11.png";
import LinkedinLogo from "./linkedin-logo.svg";
import Me from "./me_second.png";
import CloudSkillsGooglePublicProfile from "./screencapture-cloudskillsboost-google-public-profiles.png";
import CloudSkillsBoost from "./screencapture-cloudskillsboost.png";
import FjalaAl from "./screencapture-fjala-al.png";
import LinkedinFeedUpdate from "./screencapture-linkedin-feed-update.png";
import LinkedinFeed from "./screencapture-linkedin-feed.png";
import ScLinkedin from "./screencapture-linkedin.png";
import ScSyri from "./screencapture-syri-net.png";
import ReactWhiteIcon from "./react-white-icon.svg";
import AngularWhiteIcon from "./angular-white-icon.svg";
import BootstrapWhiteIcon from "./bootstrap-white-icon.svg";
import CssWhiteIcon from "./css-white-icon.svg";
import FigmaWhiteIcon from "./figma-white-icon.svg";
import GitWhiteIcon from "./git-white-icon.svg";
import HtmlWhiteIcon from "./html-white-icon.svg";
import JsWhiteIcon from "./javascript-white-icon.svg";
import NetworkingWhiteIcon from "./networking-white-icon.svg";
import NodeWhiteIcon from "./node-white-icon.svg";
import SassWhiteIcon from "./sass-white-icon.svg";
import TailwindWhiteIcon from "./tailwind-white-icon.svg";
import TsWhiteIcon from "./typescript-white-icon.svg";
import VsWhiteIcon from "./vs-white-icon.svg";
import VueWhiteIcon from "./vue-white-icon.svg";

export default {
  BonchLogo,
  CiscoLogo,
  EmailLogo,
  CloudEssentials,
  GoogleLogo,
  HtbLogo,
  HtbReward,
  ImageOne,
  ImageTwo,
  ImageThree,
  ImageFour,
  ImageFive,
  ImageSix,
  ImageSeven,
  ImageEight,
  ImageNine,
  ImageTen,
  ImageEleven,
  LinkedinLogo,
  Me,
  CloudSkillsBoost,
  CloudSkillsGooglePublicProfile,
  FjalaAl,
  LinkedinFeedUpdate,
  LinkedinFeed,
  ScLinkedin,
  ScSyri,
  ReactWhiteIcon,
  AngularWhiteIcon,
  BootstrapWhiteIcon,
  CssWhiteIcon,
  FigmaWhiteIcon,
  GitWhiteIcon,
  HtmlWhiteIcon,
  JsWhiteIcon,
  NetworkingWhiteIcon,
  NodeWhiteIcon,
  SassWhiteIcon,
  TailwindWhiteIcon,
  TsWhiteIcon,
  VsWhiteIcon,
  VueWhiteIcon,
};
