import React from "react";
import "./loader.scss";

const Loader = () => {
  const name = "XHONATANHAJDARI";
  const letters = name.split("");

  return (
    <div className="loading">
      <div className="loading-text">
        {letters.map((char, index) => (
          <span key={index} className="loading-text-words">
            {char}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Loader;
