import React from "react";
import Marquee from "react-fast-marquee";

import "./contact.scss";
import assets from "../../assets/images";

const Contact = () => {
  return (
    <div className="c" id="contact">
      <div className="c-wrapper">
        <div>
          <Marquee speed={150}>
            <p className="gradient-text">
              LET’S TALK — SAY HELLO — LET’S COLLABORATE — &nbsp;
            </p>
          </Marquee>
        </div>
        <div className="socials-contact">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/xhonatan-hajdari"
                className="linkedin-logo"
              >
                <img src={assets.LinkedinLogo} alt="linkedin" />
                <span>Linkedin</span>
              </a>
            </li>
            <li>
              <a href="mailto:xhonatanhajdari@gmail.com" className="email-logo">
                <img src={assets.EmailLogo} alt="email" />
                <span>Email</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
