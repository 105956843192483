import React from "react";
import assets from "../../assets/images";
import "./skills.scss";

const skillsList = [
  { icon: assets.JsWhiteIcon, label: "Javascript" },
  { icon: assets.ReactWhiteIcon, label: "React" },
  { icon: assets.SassWhiteIcon, label: "Sass" },
  { icon: assets.TsWhiteIcon, label: "Typescript" },
  { icon: assets.GitWhiteIcon, label: "Git" },
  { icon: assets.AngularWhiteIcon, label: "Angular" },
  { icon: assets.BootstrapWhiteIcon, label: "Bootstrap" },
  { icon: assets.NodeWhiteIcon, label: "Node" },
  { icon: assets.FigmaWhiteIcon, label: "Figma" },
  { icon: assets.HtmlWhiteIcon, label: "HTML" },
  { icon: assets.NetworkingWhiteIcon, label: "Networking" },
  { icon: assets.TailwindWhiteIcon, label: "Tailwind" },
  { icon: assets.VueWhiteIcon, label: "Vue" },
];

const Skills = () => {
  return (
    <div className="container skills-component" id="skills">
      <div className="row">
        <h1 className="title">What I'm into?</h1>
        <p className="skills-info">
          The primary area of my interest is undoubtedly front-end. <br /> My
          core expertise includes modern front-end technologies like React
          JS/TS, Angular, and Next.js, combined or not with frameworks such as
          MUI and Ant Design, which enable me to build polished, user-centric
          interfaces. With proficiency in SCSS and Tailwind, I ensure that
          designs are both visually appealing and responsive. My technical skill
          set extends to .NET, Networking, Azure DevOps, and Figma, allowing me
          to navigate complex challenges and provide comprehensive solutions.
        </p>
        <div className="skills-wrapper">
          {skillsList.map((skill, index) => (
            <div key={index}>
              <img src={skill.icon} alt={`${skill.label.toLowerCase()} logo`} />
              <p>{skill.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
